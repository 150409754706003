import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './App.less';
import App from './App';
import { datadogLogs } from '@datadog/browser-logs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

if (process.env.NODE_ENV === 'test') {
  // eslint-disable-next-line
  const { worker } = require('./mocks/browser');
  worker.start();
}

const domain = window.location.hostname;
if (domain === 'lightningpay.propark.com' && process.env.REACT_APP_DATADOG_LOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    service: 'lightning-pay-client',
    sampleRate: 100
  });
} else if (process.env.REACT_APP_DATADOG_LOG_TOKEN) {
  if (window?.location?.hostname?.includes('localhost')) {
    datadogLogs.logger = console;
  } else {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      service: 'lightning-pay-staging',
      sampleRate: 100
    });
  }
}

const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  typography: {
    fontFamily: '"Montserrat",sans-serif'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          color: '#FFF',
          borderRadius: '11px'
        }
      }
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider
      dense
      hideIconVariant
      maxSnack={1}
      autoHideDuration={null}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)} size="small">
          <Close />
        </IconButton>
      )}>
      <App />
    </SnackbarProvider>
  </ThemeProvider>
);
